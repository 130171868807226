import { Component } from '@angular/core';

@Component({
    selector: 'app-contact-us',
    templateUrl: './contact-us.component.html',
    styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent {
    supportLink: string;
    supportphone: string;
    billingLink: string;

    constructor() {
        this.supportLink = this.createMailtoLink('admin@reghub.ca', '', '');
        this.supportphone = this.createTelLink('1 (888) 508-0608');
        
        this.billingLink = this.createMailtoLink('billing@reghub.ca', '', '');
    }

    createMailtoLink(email: string, subject: string, body: string): string {
        return `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    }

    createTelLink(phoneNumber: string): string {
        return `tel:${phoneNumber}`;
      }
}