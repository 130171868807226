import { FormGroup } from "@angular/forms";

export class HandlePartycomponent {
    protected getParty(partyForm: FormGroup) {
        var partyCopy = JSON.parse(JSON.stringify(partyForm.value));

        if (!this.anyAddressControlHasValueBesidesCountryCode(partyForm)) {
            partyCopy.contactDetails.address.countryCode = null;
        }

        return partyCopy;
    }

    public anyAddressControlHasValueBesidesCountryCode(partyForm: FormGroup): boolean {
        const addressForm = partyForm.get('contactDetails')?.get('address') as any;

        if(!addressForm) {
            return false;
        }

        return Object.keys(addressForm.controls).some(key => {
            if (key === 'countryCode') {
                return false;
            }

            return addressForm.get(key)?.value;
        });
    }
}