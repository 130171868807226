import { AfterViewInit, Component, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Order, OrderManagerService, Party, ValidationProblem, ValidationService, ePartyType } from 'reg-hub-common';
import { eCriteriaVariation } from '../../services/stepper/steps/search-stepper';
import { SteppedComponent } from 'projects/reg-hub-client/src/interfaces/stepped-component';
import { StepValidationState } from 'projects/reg-hub-client/src/interfaces/step';
import { BehaviorSubject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-debtor-search-criteria',
  templateUrl: './debtor-search-criteria.component.html',
  styleUrls: ['./debtor-search-criteria.component.css']
})
export class DebtorSearchCriteriaComponent extends SteppedComponent implements AfterViewInit {
  @Input() criteriaVariations: eCriteriaVariation[] = []
  @Input() isFormEnabled: boolean = true;

  protected searchCriteriaDetails!: FormGroup;

  protected individualDebtor = ePartyType.individualDebtor;
  protected businessDebtor = ePartyType.businessDebtor;
  
  protected displayIndividualFields: boolean = false;
  protected displayBusinessFields: boolean = false;
  protected shouldDisplayDateOfBirth: boolean = false;
  protected shouldDisplayExactSimilar: boolean = false;
  protected shouldOnlyAllowMiddleInitial: boolean = false;
  protected shouldDisplaySearchFromDate: boolean = false;
  protected shouldDisplaySpecificNonSpecific: boolean = false;
  protected shouldDisplayPostalCode: boolean = false;
  protected isSpecificChosen = true;

  firstNameError$ = new BehaviorSubject<string | null | undefined>(null);
  middleNameError$ = new BehaviorSubject<string | null | undefined>(null);
  lastNameError$ = new BehaviorSubject<string | null | undefined>(null);
  dateOfBirthError$ = new BehaviorSubject<string | null | undefined>(null);
  busNameError$ = new BehaviorSubject<string | null | undefined>(null);
  postalCodeError$ = new BehaviorSubject<string | null | undefined>(null);
  searchFromDateError$ = new BehaviorSubject<string | null | undefined>(null);

  private orderToValidate: Order | null = null;

  constructor(
    private formBuilder: FormBuilder,
    orderManager: OrderManagerService,
    validationService: ValidationService) {
    super(orderManager, validationService);
  }

  protected override init(order: Order): void {
    super.init(order);

    this.shouldDisplayDateOfBirth = this.criteriaVariations.includes(eCriteriaVariation.DateOfBirth);
    this.shouldOnlyAllowMiddleInitial = this.criteriaVariations.includes(eCriteriaVariation.FirstMiddleInitialLast);
    this.shouldDisplayExactSimilar = this.criteriaVariations.includes(eCriteriaVariation.ExactSimilar);
    this.shouldDisplaySearchFromDate = this.criteriaVariations.includes(eCriteriaVariation.SearchFromDate);
    this.shouldDisplaySpecificNonSpecific = this.criteriaVariations.includes(eCriteriaVariation.SpecificNonSpecific);
    this.shouldDisplayPostalCode = this.criteriaVariations.includes(eCriteriaVariation.PostalCode);

    const debtor = this.order.parties?.at(0);
    if (debtor?.partyTypeID.toString() == "IndividualDebtor") {
      debtor!.partyTypeID = this.individualDebtor;
    }

    if (debtor?.partyTypeID.toString() == "BusinessDebtor") {
      debtor!.partyTypeID = this.businessDebtor;
    }

    this.searchCriteriaDetails = this.formBuilder.group({
      partyTypeID: [debtor?.partyTypeID],
      firstName: [debtor?.firstName],
      middleName: [debtor?.middleName],
      lastName: [debtor?.lastName],
      busName: [debtor?.busName],
    })

    if (this.shouldDisplayDateOfBirth) {
      this.searchCriteriaDetails.addControl('dateOfBirth', new FormControl(debtor?.dateOfBirth));
    }

    if (this.shouldDisplayExactSimilar) {
      this.searchCriteriaDetails.addControl('exactSimilar', new FormControl(this.order.searchParameters?.exactOnlyResults ?? true));
    }

    if (this.shouldDisplaySearchFromDate) {
      this.searchCriteriaDetails.addControl('searchFromDate', new FormControl(this.order.searchParameters?.searchFromDate));
    }

    if (this.shouldDisplayPostalCode) {
      this.searchCriteriaDetails.addControl(`postalCode`, new FormControl(debtor?.contactDetails?.address?.postalCode));
    }

    if (!this.isFormEnabled) {
      this.searchCriteriaDetails.disable();
    }

    this.searchCriteriaDetails.get('partyTypeID')!.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(val => {
        this.displayIndividualFields = (val === this.individualDebtor || val === "IndividualDebtor");
        this.displayBusinessFields = (val === this.businessDebtor || val === "BusinessDebtor");
      });
    this.searchCriteriaDetails.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.validate(this.getOrderToValidate()));
  }

  ngAfterViewInit(): void {
    this.displayIndividualFields = (this.searchCriteriaDetails.get('partyTypeID')!.value === this.individualDebtor || this.searchCriteriaDetails.get('partyTypeID')!.value === "IndividualDebtor");
    this.displayBusinessFields = (this.searchCriteriaDetails.get('partyTypeID')!.value === this.businessDebtor || this.searchCriteriaDetails.get('partyTypeID')!.value === "BusinessDebtor");
  }

  public override getValidationState(errors: ValidationProblem[] | undefined, order: Order): StepValidationState {
    return (errors?.length ?? 0) === 0 ? StepValidationState.ValidationSuccess : StepValidationState.ValidationError;
  }

  public override onSaving(): void {
    const debtor = this.order.parties?.at(0);

    if(!debtor) {
      return;
    }

    this.updateDebtor(debtor);
    this.order.debtor = debtor;
    this.updateSearchParameters(this.order);
    this.orderManager.updateOrder(this.order);
  }

  override pushErrors(errors: ValidationProblem[] | undefined): void {
    if(!errors) {
      return;
    }

    this.firstNameError$.next(errors.filter(error => error.path.includes('/firstname') || error.path.includes('/name'))?.at(0)?.userFriendlyMessage);
    this.middleNameError$.next(errors.filter(error => error.path.includes('/middlename'))?.at(0)?.userFriendlyMessage);
    this.lastNameError$.next(errors.filter(error => error.path.includes('/lastname') || error.path.includes('/name'))?.at(0)?.userFriendlyMessage);
    this.dateOfBirthError$.next(errors.filter(error => error.path.includes('/dateofbirth'))?.at(0)?.userFriendlyMessage);
    this.busNameError$.next(errors.filter(error => error.path.includes('/busname') || error.path.includes('/name'))?.at(0)?.userFriendlyMessage);
    this.postalCodeError$.next(errors.filter(error => error.path.includes('/postalcode'))?.at(0)?.userFriendlyMessage);
    this.searchFromDateError$.next(errors.filter(error => error.path.includes('searchfromdate'))?.at(0)?.userFriendlyMessage);
  }

  private getOrderToValidate(): Order {
    if(!this.orderToValidate) {
      this.orderToValidate = this.copyOrder(this.order);
    }

    const debtor = this.orderToValidate?.parties?.at(0);

    if(!debtor) {
      return this.orderToValidate!;
    }

    this.updateDebtor(debtor);
    this.orderToValidate!.debtor = debtor;
    this.updateSearchParameters(this.orderToValidate!);

    return this.orderToValidate!;
  }

  private updateDebtor(debtor: Party): void {
    debtor.partyTypeID = this.searchCriteriaDetails.get('partyTypeID')!.value;
    if (debtor.partyTypeID == this.individualDebtor) {
      debtor.firstName = this.searchCriteriaDetails.get('firstName')!.value;
      debtor.middleName = this.searchCriteriaDetails.get('middleName')!.value;
      debtor.lastName = this.searchCriteriaDetails.get('lastName')!.value;
      debtor.busName = null;

      if (this.shouldDisplayDateOfBirth) {
        debtor.dateOfBirth = this.searchCriteriaDetails.get('dateOfBirth')!.value;
      }
    } else {
      debtor.firstName = null;
      debtor.middleName = null;
      debtor.lastName = null;
      debtor.busName = this.searchCriteriaDetails.get('busName')!.value;
    }

    if (this.shouldDisplayPostalCode) {
      if (debtor.contactDetails == null) {
        debtor.contactDetails = {
          id: "",
          phoneNumber: "",
          faxNumber: "",
          email: "",
          address: {
            id: "",
            unit: "",
            streetNumber: "",
            streetName: "",
            city: "",
            countryCode: "",
            jurisdiction: "",
            postalCode: ""
          }
        }
      }

      debtor.contactDetails!.address.postalCode = this.searchCriteriaDetails.get('postalCode')!.value;
    }
  }

  private updateSearchParameters(order: Order): void {
    if (this.shouldDisplayExactSimilar) {
      order.searchParameters!.exactOnlyResults = this.searchCriteriaDetails.get('exactSimilar')!.value;
    }
    
    if (this.shouldDisplaySearchFromDate) {
      order.searchParameters!.searchFromDate = this.searchCriteriaDetails.get('searchFromDate')!.value;
    }
  }
}