import { Component, Input, OnInit } from '@angular/core';
import { Order, OrderManagerService, PathConversionService, Validation, ValidationProblem } from 'reg-hub-common';

@Component({
  selector: 'app-validation-problems',
  templateUrl: './validation-problems.component.html',
  styleUrls: ['./validation-problems.component.css']
})
export class ValidationProblemsComponent implements OnInit {
  @Input() validation: Validation | null | undefined;
  private order!: Order;

  constructor(
    private orderManagerService: OrderManagerService,
    private pathConverstionService: PathConversionService) { }

  ngOnInit(): void {
    this.order = this.orderManagerService.currentOrder.value;
  }

  convertPath(problem: ValidationProblem): string {
    return this.pathConverstionService.convertPath(this.order, problem.path);
  }

  hasWarnings() {
    return (this.validation?.warnings?.length ?? 0) > 0;
  }

  hasErrors() {
    return (this.validation?.errors?.length ?? 0) > 0;
  }
}
