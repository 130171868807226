<h3>Dealers</h3>
<!-- Dealers -->
<div *ngIf="uiConfiguration?.hasDealers" class="flex-container no-margin">
    <lib-parties-list
        partyTypeTitle="Dealer"
        [order]="order"
        [observableParties$]="parties$"
        [errors$]="observableErrors$"
        class="flex-item"
        [isEditable]="isEditable"
        [uiConfiguration]="uiConfiguration!.debtorsConfiguration!"
        [businessPartyType]="businessDealer"
        [individualPartyType]="individualDealer"
        [defaultPartyType]="businessDealer"
        [expandedParty]="newParty"
        (partyAddedEvent)="handlePartyAddedEvent($event)"
        (partyUpdatedEvent)="handlePartySavedEvent($event)"
        (partyCopiedEvent)="handlePartyCopiedEvent($event)"
        (partyRemovedEvent)="handlePartyRemovedEvent($event)"
        (partyFormValueChangedEvent)="handlePartyFormValueChangedEvent($event)">
    </lib-parties-list>
</div>