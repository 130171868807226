import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Order } from '../../interfaces/orders/order';
import { Party } from '../../interfaces/parties/party';
import { ePartyType } from '../../enums';
import { AddressFormComponent } from '../address-form/address-form.component';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';
import { ValidationProblem } from '../../interfaces/validation/validation-problem';
import { HandlePartycomponent } from '../handle-party/handle-party.component';
import { PartyUIConfiguration } from '../../interfaces/ui-configurations/party-ui-configuration';
import { EmptyEntityService } from '../../services/empty-entity-service/empty-entity.service';
import { EnumeratedTypesRepositoryService } from '../../services/enumerated-types/enumerated-types-repository.service';
import { SelectOption } from '../../interfaces/select-option';
@Component({
  selector: 'lib-handle-secured-party',
  templateUrl: './handle-secured-party.component.html',
  styleUrls: ['./handle-secured-party.component.css']
})
export class HandleSecuredPartyComponent extends HandlePartycomponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(AddressFormComponent) addressFormComponent!: AddressFormComponent;

  @Input() order!: Order;
  @Input() uiConfiguration!: PartyUIConfiguration;
  // SecuredParty to bind to fields and post to RegHub when pushing add button.
  @Input() securedParty: Party = this.emptyEntityService.getEmptyParty(ePartyType.businessSecuredParty, this.order?.id ?? '');
  @Input() errors$!: Observable<ValidationProblem[] | undefined>;

  @Output() securedPartySavedEvent = new EventEmitter<Party>();
  @Output() securedPartyFormValueChangedEvent = new EventEmitter<Party>();

  protected securedPartyDetails!: FormGroup;
  protected contactDetails!: FormGroup;

  private onDestroy$ = new Subject<void>();

  firstNameError$: BehaviorSubject<string | null | undefined> = new BehaviorSubject<string | null | undefined>(null);
  middleNameError$: BehaviorSubject<string | null | undefined> = new BehaviorSubject<string | null | undefined>(null);
  lastNameError$: BehaviorSubject<string | null | undefined> = new BehaviorSubject<string | null | undefined>(null);
  busNameError$: BehaviorSubject<string | null | undefined> = new BehaviorSubject<string | null | undefined>(null);
  emailError$: BehaviorSubject<string | null | undefined> = new BehaviorSubject<string | null | undefined>(null);
  dateOfBirthError$: BehaviorSubject<string | null | undefined> = new BehaviorSubject<string | null | undefined>(null);
  phoneNumberError$: BehaviorSubject<string | null | undefined> = new BehaviorSubject<string | null | undefined>(null);
  faxNumberError$: BehaviorSubject<string | null | undefined> = new BehaviorSubject<string | null | undefined>(null);
  generationError$: BehaviorSubject<string | null | undefined> = new BehaviorSubject<string | null | undefined>(null);

  individualSecuredParty = ePartyType.individualSecuredParty;
  businessSecuredParty = ePartyType.businessSecuredParty;
  displayIndividualFields: boolean = false;
  displayBusinessFields: boolean = false;
  
  generations: SelectOption[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private emptyEntityService: EmptyEntityService,
    private enumeratedTypeRepository: EnumeratedTypesRepositoryService) { 
    super();
  }

  private initForms() {
    if (this.securedParty.partyTypeID.toString() == "IndividualSecuredParty") {
      this.securedParty.partyTypeID = this.individualSecuredParty;
    }

    if (this.securedParty.partyTypeID.toString() == "BusinessSecuredParty") {
      this.securedParty.partyTypeID = this.businessSecuredParty;
    }

    this.contactDetails = this.formBuilder.group({
      email: [this.securedParty.contactDetails?.email],
      phoneNumber: [this.securedParty.contactDetails?.phoneNumber],
      faxNumber: [this.securedParty.contactDetails?.faxNumber]
    })

    this.securedPartyDetails = this.formBuilder.group({
      id: [this.securedParty.id],
      partyTypeID: [this.securedParty.partyTypeID],
      firstName: [this.securedParty.firstName],
      middleName: [this.securedParty.middleName],
      lastName: [this.securedParty.lastName],
      busName: [this.securedParty.busName],
      dateOfBirth: [this.securedParty.dateOfBirth],
      contactDetails: this.contactDetails
    });

    if (this.uiConfiguration.showEstate) {
      this.securedPartyDetails.addControl('isEstate', new FormControl(this.securedParty.isEstate));
    }

    if(this.uiConfiguration.showGeneration) {
      this.enumeratedTypeRepository.getGenerations().subscribe(generations => {
        this.generations = generations;
        //For some reason converting an enum | null to a number just like doesn't work. We'll need to figure out a way to 
        //populate the initial value but I've spent way to long on this right now
        this.securedPartyDetails.addControl('generationID', new FormControl(this.securedParty.generationID));
      });
    }

    this.displayIndividualFields = (this.securedPartyDetails.get('partyTypeID')!.value == this.individualSecuredParty || this.securedPartyDetails.get('partyTypeID')!.value == "IndividualSecuredParty");
    this.displayBusinessFields = (this.securedPartyDetails.get('partyTypeID')!.value == this.businessSecuredParty || this.securedPartyDetails.get('partyTypeID')!.value == "BusinessSecuredParty");
  }

  private initAddressForms() {
    if (this.addressFormComponent.addressDetails) {
      const contactDetailsFormGroup = this.securedPartyDetails.get('contactDetails') as FormGroup;
      contactDetailsFormGroup.setControl('address', this.addressFormComponent.addressDetails);
    }
  }

  private initSubscriptions() {
    this.securedPartyDetails.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.securedPartyFormValueChangedEvent.emit(this.securedPartyDetails.value));
    this.securedPartyDetails.get('partyTypeID')!.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(val => {
        this.displayIndividualFields = (val == this.individualSecuredParty || val == "IndividualSecuredParty");
        this.displayBusinessFields = (val == this.businessSecuredParty || val == "BusinessSecuredParty");
    });

    if(this.errors$) {
      this.errors$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(errors => this.pushErrors(errors));
    }
  }

  ngOnInit(): void {
    this.initForms();
  }

  ngAfterViewInit(): void {
    this.initAddressForms();
    this.initSubscriptions();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  clearFields() {
    this.securedParty = this.emptyEntityService.getEmptyParty(ePartyType.businessSecuredParty, this.order?.id ?? '');
    
    this.displayIndividualFields = this.securedParty.partyTypeID == ePartyType.individualSecuredParty;
    this.displayBusinessFields = this.securedParty.partyTypeID == ePartyType.businessSecuredParty;

    this.securedPartyDetails.reset(this.securedParty);
    this.securedPartyDetails.setErrors(null);
    this.securedPartyDetails.markAsPristine();
    this.securedPartyDetails.markAsUntouched();
    this.securedPartyDetails.updateValueAndValidity();
  }

  saveSecuredParty() {
    if (this.displayBusinessFields) {
      // if business debtor, don't save individual fields
      this.securedPartyDetails.get('firstName')?.setValue(null);
      this.securedPartyDetails.get('middleName')?.setValue(null);
      this.securedPartyDetails.get('lastName')?.setValue(null);
      this.securedPartyDetails.get('dateOfBirth')?.setValue(null);
    } else {
      // if individual debtor, don't save business fields
      this.securedPartyDetails.get('busName')?.setValue(null);
    }

    this.securedPartySavedEvent.emit(this.getParty(this.securedPartyDetails));
    this.clearFields();
  }

  pushErrors(errors: ValidationProblem[] | undefined) {
    if(!errors) {
      return;
    }

    this.addressFormComponent.pushErrors(errors);

    this.firstNameError$.next(errors?.filter(error => error.path.includes('/name') || error.path.includes('/firstname')).at(0)?.userFriendlyMessage);
    this.middleNameError$.next(errors?.filter(error => error.path.includes('/middlename')).at(0)?.userFriendlyMessage);
    this.lastNameError$.next(errors?.filter(error => error.path.includes('/name') || error.path.includes('/lastname')).at(0)?.userFriendlyMessage);
    this.busNameError$.next(errors?.filter(error => error.path.includes('/name') || error.path.includes('/busname')).at(0)?.userFriendlyMessage);
    this.dateOfBirthError$.next(errors?.filter(error => error.path.includes('/dateofbirth')).at(0)?.userFriendlyMessage);
    this.emailError$.next(errors?.filter(error => error.path.includes('/email')).at(0)?.userFriendlyMessage);
    this.phoneNumberError$.next(errors?.filter(error => error.path.includes('/phonenumber')).at(0)?.userFriendlyMessage);
    this.faxNumberError$.next(errors?.filter(error => error.path.includes('/faxnumber')).at(0)?.userFriendlyMessage);
    this.generationError$.next(errors?.filter(error => error.path.includes('/generation')).at(0)?.userFriendlyMessage);
  }

  setParty(party: Party) {
      this.securedParty = party;

      this.initForms()
      this.addressFormComponent.address = this.securedParty.contactDetails?.address;

      this.addressFormComponent.initForm();
      this.addressFormComponent.initJurisdictionsForm();

      this.initAddressForms();
      this.initSubscriptions();
  }
}
