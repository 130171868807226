<div class="padding">
    <div class="flex-container">
        <span class="form-field flex-item">
            <button mat-raised-button (click)="openSummaryDocument()" *ngIf="hasSummaryDocument()"
                color="accent" class="apply-padding padding-sides-5" aria-label="Download Result Document"
                [class.spinner]="this.isSaving" [disabled]="this.isSaving">
                Download Summary Document
                <mat-icon aria-hidden="false" aria-label="Download icon" fontIcon="download"></mat-icon>
            </button>
        </span>
        <span class="form-field flex-item">
            <button mat-raised-button (click)="openResultDocument()" *ngIf="hasResultDocument()"
                color="accent" class="apply-padding padding-sides-5" aria-label="Download Result Document"
                [class.spinner]="this.isSaving" [disabled]="this.isSaving">
                Download Result Document
                <mat-icon aria-hidden="false" aria-label="Download icon" fontIcon="download"></mat-icon>
            </button>

        </span>
    </div>
</div>