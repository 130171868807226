<div class="padding">
    <h1 class="left-box">Reports</h1>

    <div class="flex-container">
        <mat-card class="flex-item">
            <mat-card-content>
                <div class="flex-container clickable" [routerLink]="'expiring'">
                    <mat-icon class="icon-display padding-top-bottom-25 padding-sides-25">alarm</mat-icon>
                    <h2>Expiring Registration Report</h2>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>