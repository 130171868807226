import { NgModule } from '@angular/core';
import { UiCommonModule } from 'reg-hub-common';
import { environment } from '../../environments/environment'
import { ReportsComponent } from './reports/reports.component';
import { ReportsRoutingModule } from './reporting-routing.module';
import { ExpiringReportComponent } from './expiring-report/expiring-report.component';
import { SharedModule } from 'projects/reg-hub-admin/src/app/shared/shared.module';
import { DateAdapter } from '@angular/material/core';
import { RegHubDateAdapter } from 'reg-hub-common';

@NgModule({
  declarations: [
    ReportsComponent,
    ExpiringReportComponent
  ],
  imports: [
    ReportsRoutingModule,
    SharedModule,
    UiCommonModule.forRoot({ rootUrl: environment.urlAddress }),
  ],
  providers: [
    { provide: DateAdapter, useClass: RegHubDateAdapter}
  ]
})
export class ReportsModule { }