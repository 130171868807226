import { Component } from '@angular/core';
import { PartyListComponent } from '../party-list/party-list.component';
import { DatePipe } from '@angular/common';
import { EmptyEntityService } from '../../services/empty-entity-service/empty-entity.service';
import { ePartyType } from '../../enums';

@Component({
  selector: 'lib-secured-party-list',
  templateUrl: './secured-party-list.component.html',
  styleUrls: ['./secured-party-list.component.css'],
  providers: [DatePipe]
})
export class SecuredPartyListComponent extends PartyListComponent {

  constructor(
    private emptyEntityService: EmptyEntityService,
    datePipe: DatePipe) {
    super(datePipe);
  }

  protected addNewSecuredParty() {
    let newParty = this.emptyEntityService.getEmptyParty(ePartyType.businessSecuredParty, this.order?.id ?? '');
    this.partyAddedEvent.emit(newParty);
  }
}
