import { Injectable } from '@angular/core';
import { NotificationService, OrderStateRepositoryService, eOrderStatusType } from 'reg-hub-common';
import { interval, Subject, takeUntil, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ResultsPollerService {

  private stopPolling = new Subject<void>();

  constructor(private orderState: OrderStateRepositoryService,
    private notificationService: NotificationService) {}

  startPolling(orderID: string) {
    // Clear previous stop signal
    this.stopPolling = new Subject<void>();

    interval(5000)
      .pipe(
        takeUntil(this.stopPolling),
        switchMap(() => this.orderState.getOrderState(orderID))
      )
      .subscribe({
        next: (response) => {
          if (response.status?.toLowerCase() == "complete") {
            // stop polling and trigger alert.
            this.stopPollingService();

            // publish Notification
            this.notificationService.addNotification("Order Completed", `A recently requested order has completed.`, orderID)
          }
        },
        error: (error) => {
          // Handle errors here
          console.error(error);
        },
      });

    // Automatically stop polling after 60 seconds
    setTimeout(() => this.stopPolling.next(), 60000);
  }

  stopPollingService() {
    this.stopPolling.next();
  }
}
