<div *ngIf="hasWarnings()" class="validation-problems warnings">
    <span class="heading"><mat-icon class="icon">warning</mat-icon><h4>Warnings</h4></span>
    <ul *ngFor="let warning of validation?.warnings">
        <li>{{ convertPath(warning) }}: {{ warning.userFriendlyMessage }}</li>
    </ul>
</div>

<div *ngIf="hasErrors()" class="validation-problems errors">
    <span class="heading"><mat-icon class="icon">error</mat-icon><h4>Errors</h4></span>
    <ul *ngFor="let error of validation?.errors">
        <li>{{ convertPath(error) }}: {{ error.userFriendlyMessage }}</li>
    </ul>
</div>