import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatRadioModule } from '@angular/material/radio';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatBadgeModule } from '@angular/material/badge';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatTabsModule } from '@angular/material/tabs';
import { MatStepperModule } from '@angular/material/stepper';
import { UiCommonModule } from 'reg-hub-common';
import { PendingOrderListComponent } from './pending-order-list/pending-order-list.component';
import { environment } from '../../environments/environment';
import { DraftOrderListComponent } from './draft-order-list/draft-order-list.component';
import { InvalidOrderListComponent } from './invalid-order-list/invalid-order-list.component';
import { OrderListComponent } from './order-list/order-list.component';
import { CompleteOrderListComponent } from './complete-order-list/complete-order-list.component';
import { NewSearchComponent } from './new-search/new-search.component';
import { NewRegistrationComponent } from './new-registration/new-registration.component';
import { BaseOrderInformationComponent } from './base-order-information/base-order-information.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OrderFeesComponent } from './order-fees/order-fees.component';
import { OrderStepperComponent } from './order-stepper/order-stepper.component';
import { DebtorsComponent } from './debtors/debtors.component';
import { SecuredPartiesComponent } from './secured-parties/secured-parties.component';
import { CollateralComponent } from './collateral/collateral.component';
import { AssetsComponent } from './assets/assets.component';
import { GeneralCollateralComponent } from './general-collateral/general-collateral.component';
import { ReviewComponent } from './review/review.component';
import { BaseOrderInformationReviewComponent } from './base-order-information-review/base-order-information-review.component';
import { BCLienDetailsComponent } from './lien-details/bc-lien-details/bc-lien-details.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConfirmSubmitComponent } from './confirm-submit/confirm-submit.component';
import { DebtorSearchCriteriaComponent } from './debtor-search-criteria/debtor-search-criteria.component';
import { DebtorSearchReviewComponent } from './debtor-search-review/debtor-search-review.component';
import { OrderComponent } from './order/order.component';
import { OrderDetailsComponent } from './order-details/order-details.component';
import { SearchResultComponent } from './search-result/search-result.component';
import { AssetSearchCriteriaComponent } from './asset-search-criteria/asset-search-criteria.component';
import { AssetSearchReviewComponent } from './asset-search-review/asset-search-review.component';
import { ABLienDetailsComponent } from './lien-details/ab-lien-details/ab-lien-details.component';
import { MBLienDetailsComponent } from './lien-details/mb-lien-details/mb-lien-details.component';
import { ONLienDetailsComponent } from './lien-details/on-lien-details/on-lien-details.component';
import { QCLienDetailsComponent } from './lien-details/qc-lien-details/qc-lien-details.component';
import { SKLienDetailsComponent } from './lien-details/sk-lien-details/sk-lien-details.component';
import { ACOLLienDetailsComponent } from './lien-details/acol-lien-details/acol-lien-details.component';
import { OnCollateralComponent } from './on-collateral/on-collateral.component';
import { ActionsComponent } from './actions/actions.component';
import { DealersComponent } from './dealers/dealers.component';
import { AssetListComponent } from './asset-list/asset-list.component';
import { DischargeDetailsComponent } from './discharge-details/discharge-details.component';
import { ConfirmDeleteComponent } from './confirm-delete/confirm-delete.component';
import { ValidationProblemsComponent } from './validation-problems/validation-problems.component';
import { TermComponent } from './term/term.component';
import { ConfirmCancelComponent } from './confirm-cancel/confirm-cancel.component';
import { RenewalComponent } from './renewal/renewal.component';
import { RegisteringAgentComponent } from './registering-agent/registering-agent.component';
import { OrderChangesComponent } from './order-changes/order-changes.component';
import { AmendmentDetailsComponent } from './amendment-details/amendment-details.component';
import { RegHubDateAdapter } from 'reg-hub-common';
import { QCDischargeComponent } from './qc-discharge/qc-discharge.component';

@NgModule({
  declarations: [
    PendingOrderListComponent,
    DraftOrderListComponent,
    InvalidOrderListComponent,
    OrderListComponent,
    CompleteOrderListComponent,
    NewSearchComponent,
    NewRegistrationComponent,
    BaseOrderInformationComponent,
    OrderFeesComponent,
    OrderStepperComponent,
    DebtorsComponent,
    SecuredPartiesComponent,
    CollateralComponent,
    AssetsComponent,
    GeneralCollateralComponent,
    ReviewComponent,
    BaseOrderInformationReviewComponent,
    BCLienDetailsComponent,
    ConfirmSubmitComponent,
    ConfirmDeleteComponent,
    DebtorSearchCriteriaComponent,
    DebtorSearchReviewComponent,
    OrderComponent,
    OrderDetailsComponent,
    SearchResultComponent,
    AssetSearchCriteriaComponent,
    AssetSearchReviewComponent,
    ABLienDetailsComponent,
    MBLienDetailsComponent,
    ONLienDetailsComponent,
    QCLienDetailsComponent,
    SKLienDetailsComponent,
    ACOLLienDetailsComponent,
    OnCollateralComponent,
    ActionsComponent,
    DealersComponent,
    AssetListComponent,
    DischargeDetailsComponent,
    ValidationProblemsComponent,
    TermComponent,
    ConfirmCancelComponent,
    RenewalComponent,
    RegisteringAgentComponent,
    OrderChangesComponent,
    AmendmentDetailsComponent,
    QCDischargeComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    // Material Design imports
    MatProgressSpinnerModule,
    MatMenuModule,
    MatIconModule,
    MatListModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatDividerModule,
    MatCardModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatSelectModule,
    MatProgressBarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatExpansionModule,
    MatSnackBarModule,
    MatDialogModule,
    MatBadgeModule,
    MatPaginatorModule,
    MatSlideToggleModule,
    MatSortModule,
    MatGridListModule,
    MatTabsModule,
    MatRippleModule,
    MatStepperModule,
    MatExpansionModule,
    BrowserAnimationsModule,

    UiCommonModule.forRoot({ rootUrl: environment.urlAddress })
  ],
  exports: [
    BaseOrderInformationComponent,
    DraftOrderListComponent,
    InvalidOrderListComponent,
    PendingOrderListComponent,
    CompleteOrderListComponent,
    NewRegistrationComponent,
    NewSearchComponent,
    SecuredPartiesComponent,
    DebtorsComponent,
    AssetsComponent,
    ReviewComponent,
    BCLienDetailsComponent
  ],
  providers: [
    { provide: DateAdapter, useClass: RegHubDateAdapter}
  ]
})
export class OrdersModule { }
