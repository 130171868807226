import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { base64StringToBlob } from 'blob-util';
import { ListComponent, ConfigurationState, UserGroup, UserGroupRepositoryService, InformationDocument, InformationDocumentsRepositoryService } from 'reg-hub-common';
import { HttpParams } from '@angular/common/http';

@Component({
    selector: 'app-information-documents',
    templateUrl: './information-documents.component.html',
    styleUrls: ['./information-documents.component.css']
})
export class InformationDocumentsComponent implements OnInit {
    informationDocuments: InformationDocument[] = [];

    constructor(private repo: InformationDocumentsRepositoryService,
        private router: Router,
        private messageService: ToastrService) {
    }

    ngOnInit(): void {
        let params = new HttpParams().set('customerVisible',true).set('visible',true).set('sortColumn', 'Priority');
        this.repo.getFirstPageSubscribable(params).subscribe(results => {
            this.informationDocuments = results;
        })
    }

    downloadDocument(informationDocumentID: string) {
        this.repo.getInformationDocumentBinary(informationDocumentID).subscribe(result => {
            const documentType = `application/${result?.documentTypeID ?? "pdf" }`.toLowerCase();
            const blob = base64StringToBlob(result.base64, documentType);
            const blobURL = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = blobURL;
            link.download = result.fileName; // set a name for the file
            link.click();
        })
    }
}