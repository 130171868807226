<mat-card>
    <!-- <mat-card-header>
        <mat-card-title>Actions</mat-card-title>
    </mat-card-header> -->
    <mat-card-content>
        <div class="button-container">
            <button mat-flat-button color="primary" (click)="amend()">Amend</button>
            <button *ngIf="!order.lien!.isInfiniteTerm" mat-flat-button color="primary" (click)="renew()">Renew</button>
            <button mat-flat-button color="warn" (click)="discharge()">Discharge</button>
        </div>
    </mat-card-content>
</mat-card>