import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { OrderManagerService, ValidationProblem, ValidationService } from 'reg-hub-common';
import { BaseLienDetailsComponent } from '../base-lien-details.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-mb-lien-details',
  templateUrl: './mb-lien-details.component.html',
  styleUrls: ['./mb-lien-details.component.css']
})
export class MBLienDetailsComponent extends BaseLienDetailsComponent {
  perfectionInAnotherJurisdictionError$ = new BehaviorSubject<string | null | undefined>(null);
  purchaseMoneySecurityInterestError$ = new BehaviorSubject<string | null | undefined>(null);
  receiverAppointmentError$ = new BehaviorSubject<string | null | undefined>(null);

  constructor(
    formBuilder: FormBuilder,
    orderManager: OrderManagerService,
    validationService: ValidationService) {
    super(formBuilder, orderManager, validationService);
  }

  public override pushErrors(errors: ValidationProblem[] | undefined): void {
    super.pushErrors(errors);

    this.perfectionInAnotherJurisdictionError$.next(errors?.filter(error => error.path.includes('perfection'))?.at(0)?.userFriendlyMessage);
    this.purchaseMoneySecurityInterestError$.next(errors?.filter(error => error.path.includes('purchaseMoneySecurityInterest'))?.at(0)?.userFriendlyMessage);
    this.receiverAppointmentError$.next(errors?.filter(error => error.path.includes('receiverAppointment'))?.at(0)?.userFriendlyMessage);
  }

  protected override buildForm(): FormGroup {
    var form = this.formBuilder.group({ }) as FormGroup;

    if(this.uiConfiguration.lienDetailsConfiguration?.showAmount) {
      form.addControl('amount', this.formBuilder.control(this.order.lien?.amount));
    }

    if(this.uiConfiguration.lienDetailsConfiguration?.showTrustIndenture) {
      form.addControl('trustIndenture', this.formBuilder.control(this.order.lien?.trustIndenture ?? false));
    }

    if(this.uiConfiguration.lienDetailsConfiguration?.showPerfectionInAnotherJurisdiction) {
      form.addControl('perfectionInAnotherJurisdiction', this.formBuilder.control(this.order.lien?.perfectionInAnotherJurisdiction ?? false));
    }

    if(this.uiConfiguration.lienDetailsConfiguration?.showPurchaseMoneySecurityInterest) {
      form.addControl('purchaseMoneySecurityInterest', this.formBuilder.control(this.order.lien?.purchaseMoneySecurityInterest ?? false));
    }

    if(this.uiConfiguration.lienDetailsConfiguration?.showReceiverAppointment) {
      form.addControl('receiverAppointment', this.formBuilder.control(this.order.lien?.receiverAppointment ?? false));
    }

    if(this.uiConfiguration.lienDetailsConfiguration?.showAdditionalInformation) {
      form.addControl('additionalInformation', this.formBuilder.control(this.order.lien?.additionalInformation));
    }

    return form;
  }
}