import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SystemEvent, SystemEventsRepositoryService } from 'reg-hub-common';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-system-event-notification',
  templateUrl: './system-event-notification.component.html',
  styleUrls: ['./system-event-notification.component.css']
})
export class SystemEventNotificationComponent implements OnInit {
    protected _events: SystemEvent[] = [];
    protected _eventMessage: string | null = null;

    constructor(
        protected systemEventsRepo: SystemEventsRepositoryService) { }
    
      ngOnInit(): void {
        this.fetchEvents();
      }

      fetchEvents() {
        var defaultParams = new HttpParams().set('isOpen', true);
        defaultParams = defaultParams.append("isCustomerVisible", true);

        this.systemEventsRepo.getFirstPageSubscribable(defaultParams).subscribe(events => {
            this._events = events;
            this._eventMessage = events?.length > 0 ? events[0].message : null;
        });

        setTimeout(() => {
          this.fetchEvents();
        }, 10000);
      }
}