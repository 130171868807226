import { Injectable } from '@angular/core';
import { Stepper } from '../../../interfaces/stepper';
import { Order, eOrderType } from 'reg-hub-common';
import { instantiate } from './step-mapper';
import { BehaviorSubject } from 'rxjs';
import { NotFoundStepper } from './steps/not-found-stepper';
import { ValidationRule } from '../../../interfaces/validation-rule';


@Injectable({
  providedIn: 'root'
})
export class StepperService {

  private stepperSubject = new BehaviorSubject<Stepper | null>(null);
  public stepper$ = this.stepperSubject.asObservable();

  private validationRulesSubject = new BehaviorSubject<ValidationRule[] | null>(null);
  public validationRules$ = this.validationRulesSubject.asObservable();
  
  constructor() { }

  updateStepper(newStepper: Stepper) {
    this.stepperSubject.next(newStepper);
  }

  updateValidationRules(newValidationRules: ValidationRule[]) {
    this.validationRulesSubject.next(newValidationRules);
  }

  public initializeSteps(jurisdiction: string, orderType: string, qcFormType?: string): Stepper {

    var governingStepperClass = this.buildClassReference(jurisdiction, orderType, qcFormType);
    
    try {
      const stepper = instantiate(governingStepperClass);
      // this.updateStepper(stepper);
      return stepper;
    }
    catch (error) {
      return new NotFoundStepper();
    }
  }

  buildClassReference(jurisdiction: string, orderType: string, qcFormType?: string): string {
    if (jurisdiction == "" || orderType == eOrderType.notSet.toString()) {
      return 'base-info-stepper';
    }

    if (qcFormType && qcFormType != "") {
      return `${jurisdiction.toLowerCase()}-${qcFormType.toLowerCase()}-${orderType.toLowerCase()}-stepper`;
    }
    return `${jurisdiction.toLowerCase()}-${orderType.toLowerCase()}-stepper`;
  }
}
