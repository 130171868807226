import { Component } from '@angular/core';
import { OrderManagerService, ValidationService } from 'reg-hub-common';
import { BaseLienDetailsComponent } from '../base-lien-details.component';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-acol-lien-details',
  templateUrl: './acol-lien-details.component.html',
  styleUrls: ['./acol-lien-details.component.css']
})
export class ACOLLienDetailsComponent extends BaseLienDetailsComponent {
  constructor(
    formBuilder: FormBuilder,
    orderManager: OrderManagerService,
    validationService: ValidationService) {
    super(formBuilder, orderManager, validationService);
  }

  protected override buildForm(): FormGroup {
    var form = this.formBuilder.group({}) as FormGroup;

    if(this.uiConfiguration.lienDetailsConfiguration?.showAmount) {
      form.addControl('amount', this.formBuilder.control(this.order.lien?.amount));
    }

    if(this.uiConfiguration.lienDetailsConfiguration?.showAdditionalInformation) {
      form.addControl('additionalInformation', this.formBuilder.control(this.order.lien?.additionalInformation));
    }

    return form;
  }
}