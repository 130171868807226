<div class="padding">
    <h1 class="left-box">Information Documents</h1>

    <div class="flex-container" *ngFor="let infoDoc of this.informationDocuments">
        <mat-card class="flex-item padding-top-bottom-5">
            <mat-card-content>
                <a class="flex-container clickable" (click)="downloadDocument(infoDoc.id)">
                    <mat-icon class="icon-display padding-top-bottom-25 padding-sides-25">description</mat-icon>
                    <div class="flex-item-2">
                        <h2>{{infoDoc.name}}</h2>
                        <p>{{infoDoc.description}}</p>
                    </div>
                </a>
            </mat-card-content>
        </mat-card>
    </div>
</div>