import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ReportsComponent } from './reports/reports.component';
import { ExpiringReportComponent } from './expiring-report/expiring-report.component';

const routes: Routes = [
  {
    path: '',
    component: ReportsComponent,
    children: []
  },
  { path: 'expiring', component: ExpiringReportComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportsRoutingModule { }