import { Component, ViewContainerRef, Type, ChangeDetectorRef, ViewChildren, QueryList, Output, EventEmitter, Input, Injector } from '@angular/core';
import { Stepper } from '../../../interfaces/stepper';
import { StepperService } from '../../services/stepper/stepper.service';
import { eOrderType, eOrderTypeGroup, Order, OrderManagerService, OrderRepositoryService, OrderUIConfiguration, UIConfigurationService, Validation, ValidationProblem, ValidationService } from 'reg-hub-common';
import { STEPPER_GLOBAL_OPTIONS, StepperSelectionEvent } from '@angular/cdk/stepper';
import { MatStepper } from '@angular/material/stepper';
import { SearchStepper } from '../../services/stepper/steps/search-stepper';
import { Step, StepValidationState } from 'projects/reg-hub-client/src/interfaces/step';
import { BehaviorSubject, concat, map, take, tap } from 'rxjs';
import { SteppedComponent } from 'projects/reg-hub-client/src/interfaces/stepped-component';

@Component({
  selector: 'app-order-stepper',
  templateUrl: './order-stepper.component.html',
  styleUrls: ['./order-stepper.component.css'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ]
})
export class OrderStepperComponent {
  @ViewChildren('appDynamicHost', { read: ViewContainerRef }) stepHosts!: QueryList<ViewContainerRef>;

  order!: Order;

  @Input() orderId!: string;
  @Input() orderTypeGroupID!: eOrderTypeGroup;

  @Output() promptSubmissionConfirmationEvent = new EventEmitter<void>();
  @Output() promptCancelConfirmationEvent = new EventEmitter<void>();

  saving$ = new BehaviorSubject<boolean>(false);

  stepper: Stepper | null = null;
  selectedIndex = 0;
  finalStepIsSelected: boolean = false;
  currentSteppedComponent: SteppedComponent | null = null;
  shouldDisableSubmitButton: boolean = false;
  private uiConfiguration!: OrderUIConfiguration;

  constructor(
    private stepperService: StepperService,
    private orderRepository: OrderRepositoryService,
    private orderManager: OrderManagerService,
    private uiConfigurationService: UIConfigurationService,
    private cdr: ChangeDetectorRef,
    private validationService: ValidationService) { }

  ngOnInit(): void {
    this.orderManager.currentOrder
      .pipe(take(1))
      .subscribe(order => {
        this.order = order;

        if (this.order?.orderTypeID == eOrderType.amendment && this.order?.referenceRegistrationInformation?.originalQCFormTypeID) {
          this.stepper = this.stepperService.initializeSteps(order?.jurisdiction ?? "", order?.orderTypeID?.toString() ?? "", this.order!.referenceRegistrationInformation.originalQCFormTypeID.toString());
        } else if (this.order?.lien?.qcFormTypeID) {
          this.stepper = this.stepperService.initializeSteps(order?.jurisdiction ?? "", order?.orderTypeID?.toString() ?? "", this.order!.lien!.qcFormTypeID.toString());
        } else {
          this.stepper = this.stepperService.initializeSteps(order?.jurisdiction ?? "", order?.orderTypeID?.toString() ?? "");
        }

        this.finalStepIsSelected = this.stepper.steps.length === 1;
        
        this.uiConfigurationService.getOrderUIConfiguration(this.order)
          .subscribe(config => {
            this.uiConfiguration = config;
            
            setTimeout(() => {
              this.loadStepContent();
            });
          });
      });
  }

  loadStepContent() {
    if (this.stepper && this.stepHosts.length > 0) {
      var step = this.stepper!.steps[this.selectedIndex];

      if (step.content) {
        this.stepHosts.forEach(stepHost => {
          stepHost.clear();
        });

        var stepHost = this.stepHosts.toArray()[this.selectedIndex];
        const componentRef = stepHost.createComponent<unknown>(step.content as Type<unknown>);
        var component = componentRef.instance as any;
        this.currentSteppedComponent = component as SteppedComponent;
        this.currentSteppedComponent.uiConfiguration = this.uiConfiguration;
        this.currentSteppedComponent.saving$ = this.saving$.asObservable();

        if (component && this.stepper instanceof SearchStepper) {
          component.criteriaVariations = this.stepper.configuration;
        }
      }
    }

    this.cdr.detectChanges();
  }

  onStepChange(event: StepperSelectionEvent): void {
    const filter = this.currentSteppedComponent?.getValidationState;
    const selectedIndex = this.selectedIndex;

    this.currentSteppedComponent?.onSaving();
    const order = this.orderManager.getCurrentOrderValueForPut();
    this.saving$.next(true);
    this.orderRepository.updateOrder(`orders/${order.id}`, order)
      .pipe(
        tap((response: any) => this.orderManager.updateOrder(response.resource as Order)),
        map((response: any) => response.validation as Validation),
        tap(validation => {
          this.validationService.currentValidation$.next(validation);
          
          if(this.isOnFinalStep() && (validation.errors?.length ?? 0 > 0)) {
            this.shouldDisableSubmitButton = true;
          } else {
            this.shouldDisableSubmitButton = false;
          }
        }),
        map(validation => filter ? filter(this.mapPathsToLowerCase(validation.errors), order) : StepValidationState.ValidationSuccess))
      .subscribe(state => {
        this.saving$.next(false);
        this.stepper!.steps[selectedIndex].validationState = state;
      });
      
    this.selectedIndex = event.selectedIndex;
    this.finalStepIsSelected = (event.selectedIndex == this.stepper!.steps.length - 1);
    this.loadStepContent();
  }

  previousStep(orderStepper: MatStepper) {
    orderStepper.previous();
  }

  nextStep(orderStepper: MatStepper) {
    if (this.isOnFinalStep()) {
      this.promptSubmissionConfirmationEvent.emit();
    }

    orderStepper.next();
  }

  cancelInvalid() {
    if (this.order?.orderStatusTypeID == "Invalid") {
      this.promptCancelConfirmationEvent.emit();
    }
  }

  getStepIcon(step: Step): string {
    if (step.validationState === StepValidationState.DefaultState) {
      return 'edit';
    }

    const stepLabel = step.validationState === StepValidationState.ValidationSuccess ? 'validated' : 'error';
    return stepLabel;
  }
  
  private isOnFinalStep(): boolean {
    return this.selectedIndex == this.stepper!.steps.length - 1;
  }

  private mapPathsToLowerCase(problems: ValidationProblem[] | undefined): ValidationProblem[] | undefined {
    return problems?.map(problem => { 
      return { 
        path: problem.path.toLowerCase(),
        message: problem.message,
        userFriendlyMessage: problem.userFriendlyMessage,
        entityID: problem.entityID 
      }
    });
  }
}