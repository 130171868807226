<div class="padding">
    <h1 class="left-box">{{invoice?.id ?? ""}}</h1>

    <h2>Invoice Details</h2>
    <mat-card>
        <mat-card-content>
            <div class="flex-container">
                <span class="flex-item">Billing Centre:</span><span class="flex-item-2">{{ billingDetails?.name }}</span>
                <span class="flex-item">Blling Cycle:</span><span class="flex-item-2">{{ invoice?.billingCycle!.name }}</span>
            </div>
            <mat-divider></mat-divider>
            <div class="flex-container">
                <span class="flex-item">Date Opened:</span><span class="flex-item-2">{{ invoice?.dateOpened | pacificDate:'yyyy-MM-dd' }}</span>
                <span class="flex-item">Date Closed:</span><span class="flex-item-2">{{ invoice?.dateClosed | pacificDate:'yyyy-MM-dd' }}</span>
            </div>
            <div class="flex-container">
                <span class="flex-item">Date Exported:</span><span class="flex-item-2">{{ invoice?.dateExported | pacificDate:'yyyy-MM-dd' }}</span>
                <span class="flex-item">Date Paid:</span><span class="flex-item-2">{{ invoice?.datePaid | pacificDate:'yyyy-MM-dd' }}</span>
            </div>
            <mat-divider></mat-divider>
            <div class="flex-container">
                <span class="flex-item">Sub-Total:</span><span class="flex-item-2">{{ subtotal | currency }}</span>
                <span class="flex-item"><b>Total:</b></span><span class="flex-item-2"><b>{{ total | currency }}</b></span>
            </div>
        </mat-card-content>
    </mat-card>
    <h3 class="left-box">Order Fees</h3>
    <mat-card>
        <mat-card-content>
            <table mat-table [dataSource]="orderFees">

                <!-- Description Column -->
                <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef> Description </th>
                    <td mat-cell *matCellDef="let orderFee"> {{orderFee?.description}} </td>
                </ng-container>

                <!-- Product Column -->
                <ng-container matColumnDef="productCode">
                    <th mat-header-cell *matHeaderCellDef> Product Code </th>
                    <td mat-cell *matCellDef="let orderFee"> {{orderFee?.productCode}} </td>
                </ng-container>

                <!-- Tax Column -->
                <ng-container matColumnDef="taxCode">
                    <th mat-header-cell *matHeaderCellDef> Tax Code </th>
                    <td mat-cell *matCellDef="let orderFee"> {{orderFee?.taxCode}} </td>
                </ng-container>

                <!-- Amount Column -->
                <ng-container matColumnDef="amount">
                    <th mat-header-cell *matHeaderCellDef> Amount </th>
                    <td mat-cell *matCellDef="let orderFee"> {{orderFee?.amount | currency}} </td>
                </ng-container>

                <!-- Amount Column -->
                <ng-container matColumnDef="gst">
                    <th mat-header-cell *matHeaderCellDef> GST </th>
                    <td mat-cell *matCellDef="let orderFee"> {{orderFee?.gst | currency}} </td>
                </ng-container>

                <!-- Amount Column -->
                <ng-container matColumnDef="pst">
                    <th mat-header-cell *matHeaderCellDef> PST </th>
                    <td mat-cell *matCellDef="let orderFee"> {{orderFee?.pst | currency}} </td>
                </ng-container>

                <!-- Amount Column -->
                <ng-container matColumnDef="hst">
                    <th mat-header-cell *matHeaderCellDef> HST </th>
                    <td mat-cell *matCellDef="let orderFee"> {{orderFee?.hst | currency}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                </tr>
            </table>

            <div *ngIf="isLoadingOrderFees">
                <mat-spinner class="center"></mat-spinner>
            </div>
        </mat-card-content>
    </mat-card>
</div>