import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Order } from '../../interfaces/orders/order';
import { Party } from '../../interfaces/parties/party';
import { ePartyType } from '../../enums';
import { AddressFormComponent } from '../address-form/address-form.component';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';
import { ValidationProblem } from '../../interfaces/validation/validation-problem';
import { PartyUIConfiguration } from '../../interfaces/ui-configurations/party-ui-configuration';
import { HandlePartycomponent } from '../handle-party/handle-party.component';
import { EmptyEntityService } from '../../services/empty-entity-service/empty-entity.service'

@Component({
  selector: 'lib-handle-dealer',
  templateUrl: './handle-dealer.component.html',
  styleUrls: ['./handle-dealer.component.css']
})
export class HandleDealerComponent extends HandlePartycomponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(AddressFormComponent) addressFormComponent!: AddressFormComponent;

  @Input() order!: Order;
  @Input() uiConfiguration!: PartyUIConfiguration;
  // Dealer to bind to fields and post to RegHub when pushing add button.
  @Input() dealer: Party = this.emptyEntityService.getEmptyParty(ePartyType.businessDealer, this.order?.id ?? '');
  @Input() errors$!: Observable<ValidationProblem[] | undefined>;

  @Output() dealerSavedEvent = new EventEmitter<Party>();
  @Output() changesCancelledEvent = new EventEmitter<Party>();
  @Output() dealerFormValueChangedEvent = new EventEmitter<Party>();

  private onDestroy$ = new Subject<void>();

  protected dealerDetails!: FormGroup;
  protected contactDetails!: FormGroup;
  
  firstNameError$: BehaviorSubject<string | null | undefined> = new BehaviorSubject<string | null | undefined>(null);
  middleNameError$: BehaviorSubject<string | null | undefined> = new BehaviorSubject<string | null | undefined>(null);
  lastNameError$: BehaviorSubject<string | null | undefined> = new BehaviorSubject<string | null | undefined>(null);
  busNameError$: BehaviorSubject<string | null | undefined> = new BehaviorSubject<string | null | undefined>(null);
  dateOfBirthError$: BehaviorSubject<string | null | undefined> = new BehaviorSubject<string | null | undefined>(null);
  emailError$: BehaviorSubject<string | null | undefined> = new BehaviorSubject<string | null | undefined>(null);

  individualDealer = ePartyType.individualDealer;
  businessDealer = ePartyType.businessDealer;
  displayIndividualFields: boolean = false;
  displayBusinessFields: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private emptyEntityService: EmptyEntityService) {
      super();
  }

  private initForms() {
    if (this.dealer.partyTypeID.toString() == "IndividualDealer") {
      this.dealer.partyTypeID = this.individualDealer;
    }

    if (this.dealer.partyTypeID.toString() == "BusinessDealer") {
      this.dealer.partyTypeID = this.businessDealer;
    }

    this.contactDetails = this.formBuilder.group({
      email: [this.dealer.contactDetails?.email, null],
      address: this.formBuilder.group({})
    })

    this.dealerDetails = this.formBuilder.group({
      id: [this.dealer.id, null],
      partyTypeID: [this.dealer.partyTypeID],
      firstName: [this.dealer.firstName],
      middleName: [this.dealer.middleName],
      lastName: [this.dealer.lastName],
      busName: [this.dealer.busName],
      dateOfBirth: [this.dealer.dateOfBirth],
      contactDetails: this.contactDetails
    });

    if (this.uiConfiguration.showEstate) {
      this.dealerDetails.addControl('isEstate', new FormControl(this.dealer.isEstate));
    }

    this.displayIndividualFields = (this.dealerDetails.get('partyTypeID')!.value == this.individualDealer || this.dealerDetails.get('partyTypeID')!.value == "IndividualDealer");
    this.displayBusinessFields = (this.dealerDetails.get('partyTypeID')!.value == this.businessDealer || this.dealerDetails.get('partyTypeID')!.value == "BusinessDealer");
  }

  private initAddressForms() {
    if (this.addressFormComponent.addressDetails) {
      const contactDetailsFormGroup = this.dealerDetails.get('contactDetails') as FormGroup;
      contactDetailsFormGroup.setControl('address', this.addressFormComponent.addressDetails);
    }
  }

  private initSubscriptions() {
    this.dealerDetails.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.dealerFormValueChangedEvent.emit(this.getParty(this.dealerDetails)));
    this.dealerDetails.get('partyTypeID')!.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(val => {
        this.displayIndividualFields = (val == this.individualDealer || val === "IndividualDealer");
        this.displayBusinessFields = (val == this.businessDealer || val === "BusinessDealer");
    });

    if(this.errors$) {
      this.errors$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(errors => this.pushErrors(errors));
    }
  }

  ngOnInit(): void {
    this.initForms();
  }

  ngAfterViewInit(): void {
    this.initAddressForms();
    this.initSubscriptions();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  clearFields() {
    this.dealer = this.emptyEntityService.getEmptyParty(ePartyType.businessDealer, this.order?.id ?? '');
    
    this.displayIndividualFields = this.dealer.partyTypeID == ePartyType.individualDealer;
    this.displayBusinessFields = this.dealer.partyTypeID == ePartyType.businessDealer;

    this.dealerDetails.reset(this.dealer);
    this.dealerDetails.setErrors(null);
    this.dealerDetails.markAsPristine();
    this.dealerDetails.markAsUntouched();
    this.dealerDetails.updateValueAndValidity();
  }

  saveDealer() {
    if (this.displayBusinessFields) {
      // if business debtor, don't save individual fields
      this.dealerDetails.get('firstName')?.setValue(null);
      this.dealerDetails.get('middleName')?.setValue(null);
      this.dealerDetails.get('lastName')?.setValue(null);
      this.dealerDetails.get('dateOfBirth')?.setValue(null);
    } else {
      // if individual debtor, don't save business fields
      this.dealerDetails.get('busName')?.setValue(null);
    }

    this.dealerSavedEvent.emit(this.getParty(this.dealerDetails));
    this.clearFields();
  }

  pushErrors(errors: ValidationProblem[] | undefined) {
    if(!errors) {
      return;
    }

    this.addressFormComponent.pushErrors(errors);

    this.firstNameError$.next(errors?.filter(error => error.path.includes('/name') || error.path.includes('/firstname')).at(0)?.userFriendlyMessage);
    this.middleNameError$.next(errors?.filter(error => error.path.includes('/middlename')).at(0)?.userFriendlyMessage);
    this.lastNameError$.next(errors?.filter(error => error.path.includes('/name') || error.path.includes('/lastname')).at(0)?.userFriendlyMessage);
    this.busNameError$.next(errors?.filter(error => error.path.includes('/name') || error.path.includes('/busname')).at(0)?.userFriendlyMessage);
    this.dateOfBirthError$.next(errors?.filter(error => error.path.includes('/dateofbirth')).at(0)?.userFriendlyMessage);
    this.emailError$.next(errors?.filter(error => error.path.includes('/email')).at(0)?.userFriendlyMessage);
  }

  setParty(party: Party) {
    this.dealer = party;

    this.initForms()
    this.addressFormComponent.address = this.dealer.contactDetails?.address;

    this.addressFormComponent.initForm();
    this.addressFormComponent.initJurisdictionsForm();

    this.initAddressForms();
    this.initSubscriptions();
  }
}
