import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Order, Asset, AssetRepositoryService, SelectOption, AssetListComponent, OrderManagerService, OrderLogForCreation, OrderLogsRepositoryService, ValidationProblem, UIConfigurationService, AssetUIConfiguration } from 'reg-hub-common';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';
import { ClientAuthService } from '../../services/auth/client-auth.service';

@Component({
  selector: 'app-assets',
  templateUrl: './assets.component.html',
  styleUrls: ['./assets.component.css']
})
export class AssetsComponent implements OnInit, OnDestroy {
  @ViewChild(AssetListComponent) assetListComponent!: AssetListComponent;

  @Input() errors$!: Observable<ValidationProblem[] | undefined>;
  @Input() uiConfiguration!: AssetUIConfiguration;
  order!: Order;

  @Output() assetFormValueChangedEvent = new EventEmitter<Asset>();

  assets$ = new BehaviorSubject<Asset[]>([]);

  private onDestroy$ = new Subject<void>();

  protected isEditable: boolean = true;
  protected assetTypes: SelectOption[] = [];

  protected expandedAsset: Asset | null = null;

  constructor(private assetRepo: AssetRepositoryService,
    private orderManager: OrderManagerService,
    private orderLogService: OrderLogsRepositoryService,
    private authService: ClientAuthService) { }

  ngOnInit(): void {
    this.order = this.orderManager.currentOrder.value;
    this.assets$.next(this.order!.assets ?? []);
    this.orderManager.currentOrder
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(order => this.order = order );
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  handleAssetSaveEvent(savedAsset: Asset) {
    // if we don't have an ID yet, create the Asset
    if (!savedAsset.id) {
      this.createAsset(savedAsset);
    }
    // otherwise, the Asset already exists, so we should update it.
    else {
      this.updateAsset(savedAsset);
    }
  }

  handleAssetRemovedEvent(removedAsset: Asset) {
    this.assetRepo.deleteAsset(`orders/${this.order.id}/assets/${removedAsset.id}`)
      .subscribe(() => {
        const index = this.order.assets!.findIndex(obj => obj.id === removedAsset.id || !obj.id);

        // as long as our index was found 
        if (index != -1) {
            this.order.assets!.splice(index, 1);
            this.orderManager.updateOrder(this.order);
            this.assets$.next(this.order.assets ?? []);
        }
      });
  }

  handleAssetCopiedEvent(copiedAsset: Asset) {
    // create a full copy of the party so we don't overwrite the party from which we copied
    const copiedAssetWithoutId = { ...copiedAsset };
    copiedAssetWithoutId.id = "";

    this.handleAssetAddedEvent(copiedAssetWithoutId);
  }

  handleVinDecodedEvent(orderLog: OrderLogForCreation) {
    orderLog.username = this.authService.getUser()?.profile.name ?? "";
    this.orderLogService.createLog(this.order.id, orderLog).subscribe();
  }

  handleAssetFormValueChange(asset: Asset) {
    this.assetFormValueChangedEvent.emit(asset);
  }

  handleAssetAddedEvent(asset: Asset) {
    this.createAsset(asset);
  }

  private createAsset(asset: Asset) {
    this.assetRepo.createAsset(`orders/${this.order.id}/assets`, asset)
      .subscribe(result => {
        const asset = ((result as any).resource) as Asset;

        this.order.assets!.push(asset);
        this.orderManager.updateOrder(this.order);
        this.assets$.next(this.order.assets ?? []);
        this.expandedAsset = asset;
      });
  }

  private updateAsset(asset: Asset) {
    this.assetRepo.updateAsset(`orders/${this.order.id}/assets/${asset.id}`, asset)
      .subscribe(result => {
        const asset = ((result as any).resource) as Asset;

        const index = this.order.assets!.findIndex(obj => obj.id === asset.id);

        // as long as our index was found 
        if (index != -1) {
          this.order.assets![index] = asset;
          this.orderManager.updateOrder(this.order);
          this.assets$.next(this.order.assets ?? []);
        }
      });
  }
}
