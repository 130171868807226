<mat-stepper #orderStepper [linear]="false" orientation="horizontal" animationDuration="750" selectedIndex="selectedIndex" (selectionChange)="onStepChange($event)">
    <mat-step *ngFor="let step of stepper?.steps" [state]="getStepIcon(step)" completed="false">
        <ng-template matStepLabel>
          <label [ngClass]="stepper?.steps?.indexOf(step) === selectedIndex ? '' : ''">{{ step.title }}</label>
        </ng-template>
        <!-- Here we dynamically load content based on step.content -->
        <ng-template #appDynamicHost></ng-template>

        <!-- Navigation buttons -->
        <div class="flex-container padding-top">
            <button
                *ngIf="selectedIndex != 0"
                mat-raised-button
                (click)="previousStep(orderStepper)"
                class="apply-padding flex-item form-field previous-button"
                aria-label="Previous step button"
                >
                Previous
            </button>
            <span *ngIf="selectedIndex == 0" class="spacer"></span>
            <span class="spacer"></span>
            <span class="spacer"></span>
            <span class="spacer"></span>
            <span class="spacer"></span>

            <button
                *ngIf="order.orderStatusTypeID == 'Invalid'"
                mat-raised-button
                (click)="cancelInvalid()"
                color="warn"
                class="apply-padding flex-item form-field"
                aria-label="Cancel invalid button"
                >
                Cancel
            </button>
            <span class="spacer"></span>
            <button
                mat-raised-button
                (click)="nextStep(orderStepper)"
                color="primary"
                class="apply-padding flex-item form-field"
                aria-label="Next step button"
                [disabled]="shouldDisableSubmitButton"
                >
                {{ finalStepIsSelected ? "Submit" : "Next"}}
            </button>
            <!-- [class.spinner]="this.isSaving"
                [disabled]="this.isSaving" -->
        </div>

        <!-- <button mat-button matStepperPrevious>Back</button>
        <button mat-button matStepperNext>Next</button> -->
    </mat-step>

    <!-- Icon overrides. -->
  <ng-template matStepperIcon="edit">
    <mat-icon>edit</mat-icon>
  </ng-template>
  <ng-template matStepperIcon="validated">
    <mat-icon>check</mat-icon>
  </ng-template>
  <ng-template matStepperIcon="error">
    <mat-icon>close</mat-icon>
  </ng-template>
</mat-stepper>