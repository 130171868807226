<form #form="ngForm">
    <app-on-collateral *ngIf="uiConfiguration.lienDetailsConfiguration?.showMVIncluded" [order]="order"
        [errors$]="observableErrors$"
        (ontarioCollateralFormValueChangedEvent)="handleCollateralOntarioCollateralFormValueChangedEvent($event)"></app-on-collateral>

    <app-general-collateral *ngIf="uiConfiguration.collateralConfiguration?.hasGeneralCollateral"
        [uiConfiguration]="uiConfiguration!.collateralConfiguration!.generalCollateralConfiguration!" [order]="order!"
        [errors$]="observableErrors$"
        (generalCollateralFormValueChangedEvent)="handleGeneralCollateralFormValueChangedEvent($event)"></app-general-collateral>

    <app-assets *ngIf="uiConfiguration.collateralConfiguration?.hasAssets" 
        [uiConfiguration]="uiConfiguration.collateralConfiguration?.assetConfiguration!"
        [errors$]="observableErrors$"
        (assetFormValueChangedEvent)="handleAssetFormValueChangedEvent($event)"></app-assets>
</form>