import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfigurationState, ListComponent, Order } from 'reg-hub-common';
import { ExpiringReportRepositoryService, UserGroupRepositoryService } from 'reg-hub-common';

@Component({
    selector: 'app-expiring-report',
    templateUrl: './expiring-report.component.html',
    styleUrls: ['./expiring-report.component.css']
})
export class ExpiringReportComponent extends ListComponent<Order> {
    protected _expandedOrder: Order | null = null;
    private _corporationID!: string;
    protected _rangeOptions: number[] = [30, 60, 90, 180, 365];
    protected _filterForm!: FormGroup;

    displayedColumns: string[] = [
        'referenceNumber',
        'orderTypeID',
        'country',
        'jurisdiction',
        'dateRequested',
        'expiryDate',
        'view'
    ];

    constructor(
        repo: ExpiringReportRepositoryService,
        private configurationState: ConfigurationState,
        private router: Router,
        private formBuilder: FormBuilder) {
        super(repo);
    }

    ngOnInit(): void {
        this._filterForm = this.formBuilder.group({
            range: [30, Validators.required]
        });

        this.configurationState.getCorporationID().then(corporationID => {
            this._corporationID = corporationID;
            this.request();
        });
    }

    request() {
        var rangeControl = this._filterForm.get('range') as FormControl;

        this.repo.populateBaseLink([this._corporationID, rangeControl.value]);
        this.repo.getFirstPage();
    }

    protected viewOrder(order: Order, newTab: boolean) {
        if (newTab) {
          const url = this.router.serializeUrl(
            this.router.createUrlTree(['orders', order.id])
          );
      
          window.open(url, '_blank');
        }
        else {
          this.router.navigate(['orders', order.id])
        }
      }
}