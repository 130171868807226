import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiCommonModule } from 'reg-hub-common';
import { environment } from '../../environments/environment';
import { SharedModule } from 'projects/reg-hub-admin/src/app/shared/shared.module';
import { AccountingComponent } from './accounting/accounting.component';
import { AccountingRoutingModule } from './accounting-routing.module';
import { AccountingOptionsComponent } from './accounting-options/accounting-options.component';
import { ServicePricingComponent } from './service-pricing/service-pricing.component';
import { ServicePricingFilterComponent } from './service-pricing-filter/service-pricing-filter.component';
import { DisbursementPricingFilterComponent } from './disbursement-pricing-filter/disbursement-pricing-filter.component';
import { DisbursementPricingComponent } from './disbursement-pricing/disbursement-pricing.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { InvoicesFilterComponent } from './invoices-filter/invoices-filter.component';
import { InvoiceInfoComponent } from './invoice-info/invoice-info.component';
import { DateAdapter } from '@angular/material/core';
import { RegHubDateAdapter } from 'reg-hub-common';

@NgModule({
    declarations: [
        AccountingComponent,
        AccountingOptionsComponent,
        ServicePricingFilterComponent,
        ServicePricingComponent,
        DisbursementPricingFilterComponent,
        DisbursementPricingComponent,
        InvoicesFilterComponent,
        InvoicesComponent,
        InvoiceInfoComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        UiCommonModule.forRoot({ rootUrl: environment.urlAddress }),
        AccountingRoutingModule
    ],
    providers: [
      { provide: DateAdapter, useClass: RegHubDateAdapter}
    ]
})
export class AccountingModule { }