<div class="padding">
    <div class="flex-container" *ngIf="!isLoadingOrder">
        <div class="flex-item">
            <div class="padding-sides-15">
                <mat-card>
                    <mat-card-content>
                        <mat-horizontal-stepper #orderStepper [linear]="false" selectedIndex="selectedIndex">
                            <mat-step label="Order Info" completed="false">
                                <form #form="ngForm">
                                    <div class="padding">
                                        <form [formGroup]="baseOrderDetails" class="padding">
                                            <div class="flex-container">
                                                <mat-form-field class="flex-item form-field" appearance="outline">
                                                    <mat-label>Reference Number</mat-label>
                                                    <input matInput name="reference-number" [formControlName]="'referenceNumber'" placeholder="Reference Number">
                                                </mat-form-field>
                                            </div>
                                            <div class="flex-container">
                                                <mat-form-field class="flex-item form-field" appearance="outline">
                                                    <mat-label>Order Type</mat-label>
                                                    <mat-select name="order-type-id" [formControlName]="'orderTypeID'" (selectionChange)="onOrderTypeSelectionChange()">
                                                        <mat-option *ngFor="let orderType of orderTypes" [value]="orderType.value">
                                                        {{orderType.label}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                                <mat-form-field *ngIf="isOrderTypeChosen" class="flex-item form-field" appearance="outline">
                                                    <mat-label>Jurisdiction</mat-label>
                                                    <mat-select name="jurisdiction" [formControlName]="'jurisdiction'" (selectionChange)="handleJurisdictionChangeEvent()">
                                                        <mat-option *ngFor="let jurisdiction of jurisdictions" [value]="jurisdiction.jurisdictionCode">
                                                        {{jurisdiction.jurisdictionFullName}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>

                                            <div class="flex-container padding-top" *ngIf="orderIsQC && orderIsLien">
                                                <mat-form-field class="flex-item form-field" appearance="outline">
                                                    <mat-label>Form Type</mat-label>
                                                    <mat-select name="qc-from-type" [formControlName]="'qcFormTypeID'">
                                                        <mat-option *ngFor="let qcFormType of qcFormTypes" [value]="qcFormType.value">
                                                        {{qcFormType.label}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>

                                            <div class="flex-container padding-top" *ngIf="orderIsRenewal || orderIsDischarge">
                                                <mat-form-field class="flex-item form-field" appearance="outline">
                                                    <mat-label>Original Order ID</mat-label>
                                                    <input matInput name="original-order-id" [formControlName]="'originalOrderID'" placeholder="Original Order ID">
                                                </mat-form-field>
                                            </div>
                                        </form>
                                    </div>
                                </form>
                                <!-- Navigation buttons -->
                                <div class="flex-container padding-top">
                                    <span class="spacer"></span>
                                    <span class="spacer"></span>
                                    <span class="spacer"></span>
                                    <span class="spacer"></span>
                                    <span class="spacer"></span>

                                    <button
                                        mat-raised-button
                                        (click)="saveContents()"
                                        color="primary"
                                        class="apply-padding flex-item form-field"
                                        aria-label="Next step button"
                                        >
                                        Next
                                    </button>
                                </div>

                            </mat-step>
                        </mat-horizontal-stepper>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>

    <!-- Spinner (replaces everything and waits until all requests have completed to show anything) -->
    <div *ngIf="this.isLoadingOrder" class="loading-spinner-overlay">
        <div class="loading-spinner-overlay">
            <mat-spinner></mat-spinner>
        </div>
    </div>
</div>
<!-- End of spinner -->