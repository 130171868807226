import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { ReusablePartyForCreation, SelectOption, ePartyType } from 'reg-hub-common';
import { ReusablePartyRepositoryService, ConfigurationState, UserGroupRepositoryService } from 'reg-hub-common';
import { ToastrService } from 'ngx-toastr';
import { catchError, of } from 'rxjs';

@Component({
    selector: 'app-add-reusable-party',
    templateUrl: './add-reusable-party.component.html',
    styleUrls: ['./add-reusable-party.component.css']
})
export class AddReusablePartyComponent {
    hasAccess: boolean = false;
    isSaving: boolean = false;
    corporationID: string = "";
    partyTypes: SelectOption[] = [
        { label: "Business Secured Party", value: "BusinessSecuredParty" },
        { label: "Individual Secured Party", value: "IndividualSecuredParty" }
    ];
    reusableParty: ReusablePartyForCreation = {
        partyTypeID: ePartyType.businessSecuredParty,
        contactDetails: {
            email: '',
            phoneNumber: '',
            faxNumber: '',
            address: {
                unit: '',
                streetNumber: '',
                streetName: '',
                city: '',
                countryCode: 'CA',
                jurisdiction: '',
                postalCode: ''
            }
        },
        reusablePartyCodes: []
    }

    constructor(private repo: ReusablePartyRepositoryService,
        private configurationState: ConfigurationState,
        private messageService: ToastrService,
        private router: Router) { }

    ngOnInit(): void {
        this.configurationState.hasManageReusablePartyAccess().then(hasAccess => {
            this.hasAccess = hasAccess;
            if (!this.hasAccess) {
                //You shouldn't be here
                this.messageService.error("Access Denied");
            }
            else {
                this.configurationState.getCorporationID().then(corporationID => {
                    this.repo.populateBaseLink([corporationID]);
                    this.corporationID = corporationID;
                })
            }
        });
    }

    protected jurisdictionFormChanges(event: FormGroup) {
        let countryControl = event.controls['Country'];
        let jurisdictionControl = event.controls['Jurisdiction'];
    }

    isBusiness(): boolean {
        return (this.reusableParty.partyTypeID.toString() == "BusinessDebtor" ||
            this.reusableParty.partyTypeID.toString() == "BusinessRegisteringAgent" ||
            this.reusableParty.partyTypeID.toString() == "BusinessSecuredParty" ||
            this.reusableParty.partyTypeID.toString() == "BusinessDealer");
    }

    saveReusableParty() {
        if (!this.hasAccess) {
            //How did you get here?
            this.messageService.error("Access Denied!");
        }
        
        this.isSaving = true;
        this.repo.createReusableParty(this.corporationID, this.reusableParty)
            .pipe(
                catchError(error => {
                    // reactivate save button
                    this.isSaving = false;
                    // Return an observable to continue the chain
                    return of(null);
                })
            )
            .subscribe(result => {
                if (result && result.id != "") {
                    this.isSaving = false;
                    this.router.navigate(['management'], { queryParams: { tab: 'reusableParties' } });
                }
            })
    }
}