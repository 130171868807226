import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Party } from '../../interfaces/parties/party';
import { Order } from '../../interfaces/orders/order';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { Observable } from 'rxjs';
import { ValidationProblem } from '../../interfaces/validation/validation-problem';
import { PartyUIConfiguration } from '../../interfaces/ui-configurations/party-ui-configuration';
import { ePartyType } from '../../enums';

@Component({
  selector: 'lib-party-list',
  templateUrl: './party-list.component.html',
  styleUrls: ['./party-list.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class PartyListComponent implements OnInit {
  @Input() title: string = "";
  @Input() order!: Order;
  @Input() uiConfiguration!: PartyUIConfiguration;
  @Input() errors$!: Observable<ValidationProblem[] | undefined>;
  @Input() expandedParty: Party | null = null;

  @Input() isEditable: boolean = false;

  @Output() partyAddedEvent = new EventEmitter<Party>();
  @Output() partyUpdatedEvent = new EventEmitter<Party>();
  @Output() partyCopiedEvent = new EventEmitter<Party>();
  @Output() partyRemovedEvent = new EventEmitter<Party>();
  @Output() partyFormValueChangedEvent = new EventEmitter<Party>();

  @Input() observableParties$!: Observable<Party[]>;

  protected showPhone: boolean = false;
  protected showFax: boolean = false;
  protected showEmail: boolean = false;
  protected showGeneration: boolean = false;
  protected showEstate: boolean = false;

  displayedColumns: string[] = ['name', 'party-type', 'contactDetails'];
  isLoading: boolean = false;

  constructor(private datePipe: DatePipe) { }

  ngOnInit(): void {
    if (this.uiConfiguration.showEstate) {
      this.displayedColumns = this.displayedColumns.concat(['estate']);
    }

    if (this.uiConfiguration.showRegistryCode) {
      this.displayedColumns = this.displayedColumns.concat(['registry-code']);
    }

    this.showPhone = this.uiConfiguration.contactDetailsConfiguration.showPhoneNumber;
    this.showFax = this.uiConfiguration.contactDetailsConfiguration.showFaxNumber;
    this.showEmail = this.uiConfiguration.contactDetailsConfiguration.showEmail;
    this.showGeneration = this.uiConfiguration.showGeneration;
    this.showEstate = this.uiConfiguration.showEstate;

    if (this.isEditable) {
      this.displayedColumns = this.displayedColumns.concat(['actions']);
    }
  }

  onEditButtonClick = (event: Event, partyToEdit: Party) => {
    if (this.isEditable) {
      this.expandedParty = this.expandedParty === partyToEdit ? null : partyToEdit;
    }
  }

  onDeleteButtonClick = (event: Event, partyToDelete: Party, rowIndex: number) => {
    // emit this party to delete
    this.partyRemovedEvent.emit(partyToDelete);
  }

  onCopyButtonClick = (event: Event, partyToCopy: Party) => {

    // emit this party to copy
    this.partyCopiedEvent.emit(partyToCopy);
  }

  onUndoButtonClick = (event: Event, partyToUndo: Party, rowIndex: number) => {

    // emit this party to delete
    //this.partyRemovedEvent.emit(partyToUndo);

    // filter out deleted party from list of displayed parties
    //this.parties = this.parties.filter((u) => u.id !== partyToUndo.id);
    //this.order.parties = this.parties;
  }

  handlePartyUpdateEvent(party: Party) {
    this.partyUpdatedEvent.emit(party);
    this.expandedParty = null;
  }

  handleChangesCancelledEvent(party: Party) {
    this.expandedParty = null;
  }

  handlePartyFormValueChangedEvent(party: Party) {
    this.partyFormValueChangedEvent.emit(party);
  }

  getName(party: Party) {
    let name: string | null | undefined;

    if (this.isIndividual(party)) {
      var fullName = "";
      if (party.middleName == null || party.middleName == "") {
        fullName = party.firstName + " " + party.lastName;
      } else {
        fullName = party.firstName + " " + party.middleName + " " + party.lastName;
      }

      if (party.generationID && this.showGeneration) {
        fullName += `, ${party.generationID}`;
      }

      if (party.dateOfBirth) {
        let formattedDateOfBirth = this.datePipe.transform(party.dateOfBirth, 'yyyy-MM-dd');
        fullName += ` (${formattedDateOfBirth})`;
      }

      name = fullName.trim();
    } else {
      name = party.busName?.trim();
    }

    if (name) {
      return name;
    } else {
      var fullType = this.getPartyTypeString(party);
      var splitType = fullType.split(' ');
      var type = splitType.slice(1).join(' ');

      return `New ${type}`;
    }
  }

  getAddress(party: Party) {
    const addressFields = [party.contactDetails?.address?.unit,
    party.contactDetails?.address?.streetNumber,
    party.contactDetails?.address?.streetName,
    party.contactDetails?.address?.city,
    party.contactDetails?.address?.jurisdiction,
    party.contactDetails?.address?.countryCode,
    party.contactDetails?.address?.postalCode
    ].filter(field => field);

    let result: string = '';
    const isJurisdictionNotNull = !!party.contactDetails?.address?.jurisdiction;

    for (let i = 0; i < addressFields.length; i++) {
      result += addressFields[i];

      if (isJurisdictionNotNull && addressFields[i] === party.contactDetails?.address.jurisdiction) {
        result += ',';
      }

      if (i != addressFields.length - 1) {
        result += ' ';
      }
    }

    result = result.trim();

    if (result.startsWith(',')) {
      result = result.slice(1);
    }

    if (result.endsWith(',')) {
      result = result.slice(0, result.length - 2);
    }

    return result;
  }

  getContactDetails(party: Party) {

    let result: string = this.getAddress(party);

    if (party.contactDetails?.email && this.showEmail) {
      result += `<br>Email: ${party.contactDetails.email}`
    }

    if (party.contactDetails?.phoneNumber && this.showPhone) {
      result += `<br>Phone: ${party.contactDetails.phoneNumber}`
    }

    if (party.contactDetails?.faxNumber && this.showFax) {
      result += `<br>Fax: ${party.contactDetails.faxNumber}`
    }

    return result;
  }

  getPartyTypeString(party: Party) {
    if (party.partyType?.name) {
      return party.partyType.name;
    }

    switch (party.partyTypeID.toString() ?? party.partyType.id.toString()) {
      case "BusinessDebtor":
        return "Business Debtor";
      case "BusinessSecuredParty":
        return "Business Secured Party";
      case "IndividualDebtor":
        return "Individual Debtor";
      case "IndividualSecuredParty":
        return "Individual Secured Party";
      case "BusinessRegisteringAgent":
        return "Business Registering Agent";
      case "IndividualRegisteringAgent":
        return "Individual Registering Agent";
      case "IndividualDealer":
        return "Individual Dealer";
      case "BusinessDealer":
        return "Business Dealer";
      default:
        return "";
    }
  }

  isIndividual(party: Party) {
    return party.partyTypeID == ePartyType.individualDebtor ||
      party.partyTypeID == ePartyType.individualSecuredParty ||
      party.partyTypeID == ePartyType.individualDealer ||
      party.partyTypeID == ePartyType.individualRegisteringAgent ||
      party.partyTypeID.toString() == "IndividualDebtor" ||
      party.partyTypeID.toString() == "IndividualSecuredParty" ||
      party.partyTypeID.toString() == "IndividualDealer" ||
      party.partyTypeID.toString() == "IndividualRegisteringAgent"
  }

  isEstate(party: Party) {
    return party.isEstate && this.showEstate ? "YES" : "NO";
  }
}

