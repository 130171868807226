<div class="flex-container">
    <mat-list class="flex-item">
        <mat-list-item>
            <div class="list-item-content">
                <span class="label">Reference Number: </span>
                <span class="value">{{ order.referenceNumber }}</span>
            </div>
        </mat-list-item>
        <mat-list-item>
            <div class="list-item-content">
                <span class="label">Order Type:</span>
                <span class="value">{{ order.orderType.name }}</span>
            </div>
        </mat-list-item>
        <mat-list-item *ngIf="showQcForm(order)">
            <div class="list-item-content">
                <span class="label">Form Type:</span>
                <span class="value">{{ order.lien!.qcFormTypeID! }}</span>
            </div>
        </mat-list-item>
    </mat-list>

    <mat-list class="flex-item">
        <mat-list-item>
            <div class="list-item-content">
                <span class="label">Country:</span>
                <span class="value">{{ order.country }}</span>
            </div>
        </mat-list-item>
        <mat-list-item>
            <div class="list-item-content">
                <span class="label">Jurisdiction:</span>
                <span class="value">{{ order.jurisdiction }}</span>
            </div>
        </mat-list-item>
    </mat-list>
</div>