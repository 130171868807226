import { Component } from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';
import { Order, OrderManagerService, ValidationProblem, ValidationService } from 'reg-hub-common';
import { BaseLienDetailsComponent } from '../base-lien-details.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-sk-lien-details',
  templateUrl: './sk-lien-details.component.html',
  styleUrls: ['./sk-lien-details.component.css']
})
export class SKLienDetailsComponent extends BaseLienDetailsComponent {
  rinError$ = new BehaviorSubject<string | null | undefined>(null);

  constructor(
    formBuilder: FormBuilder,
    orderManager: OrderManagerService,
    validationService: ValidationService
  ) {
    super(formBuilder, orderManager, validationService);
  }

  override onSaving(): void {
    super.onSaving();
    this.setRin(this.order);
  }
  
  override onTermSelectionChange(event: MatRadioChange): void {
    if (event.value === true) {
      this.order.lien!.expiryDate = null;
      this.order.lien!.term = null;
    }
  }

  protected override getOrderToValidate(): Order {
    let order = super.getOrderToValidate();
    this.setRin(order);
    return order;
  }

  public override pushErrors(errors: ValidationProblem[] | undefined): void {
    super.pushErrors(errors);

    this.rinError$.next(errors?.filter(error => error.path.includes('/rin'))?.at(0)?.userFriendlyMessage);
  }

  protected override buildForm(): FormGroup {
    var form = this.formBuilder.group({
      amount: [this.order.lien?.amount],
      customRin: [this.getCustomRinInitialValue()],
      rin: [this.order.lien?.rin],
      trustIndenture: [this.order.lien?.trustIndenture ?? false]
    });

    if(this.uiConfiguration.lienDetailsConfiguration?.showRIN) {
      form.addControl('customRin', this.formBuilder.control(this.getCustomRinInitialValue()));
      form.addControl('rin', this.formBuilder.control(this.order.lien?.rin));
    }

    if(this.uiConfiguration.lienDetailsConfiguration?.showTrustIndenture) {
      form.addControl('trustIndenture', this.formBuilder.control(this.order.lien?.trustIndenture ?? false));
    }

    return form;
  }

  private setRin(order: Order) {
    const rinControl = this.lienDetails.get('customRin');

    if(rinControl?.value === 'no') {
      order.lien!.noRIN = true;
      order.lien!.rin = null;
      order.lien!.systemGeneratedRIN = false;
    } else if(rinControl?.value === 'choose') {
      order.lien!.noRIN = false;
      order.lien!.rin = this.lienDetails.get('rin')?.value;
      order.lien!.systemGeneratedRIN = false;
    } else if(rinControl?.value === 'system') {
      order.lien!.noRIN = false;
      order.lien!.rin = null;
      order.lien!.systemGeneratedRIN = true;
    }
  }

  private getCustomRinInitialValue(): string {
    if(this.order.lien?.rin) {
      return 'choose';
    } else if(this.order.lien?.systemGeneratedRIN) {
      return 'system';
    } else {
      return 'no';
    }
  }
}