import { UserManagerSettings } from "oidc-client-ts";

export const environment = {
    production: false,
    urlAddress: 'https://bapi.uat.reghub.ca/api/v1'
  };

export const authConfig: UserManagerSettings = {
  authority: 'https://auth.uat.reghub.ca',
  client_id: 'web.client',
  redirect_uri: 'https://client.uat.reghub.ca/callback',
  response_type: 'code',
  scope: 'openid profile offline_access RegHubAPI',
  post_logout_redirect_uri: 'https://client.uat.reghub.ca',
  loadUserInfo: true
};

export const idleTimers = {
    idleTimeoutInSeconds: 1500,
    logoutWarningCountdownInSeconds: 60
};