import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { Order, OrderFee, OrderFeeRepositoryService, OrderManagerService } from 'reg-hub-common';

@Component({
  selector: 'app-order-fees',
  templateUrl: './order-fees.component.html',
  styleUrls: ['./order-fees.component.css']
})
export class OrderFeesComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<void>();
  isComplete: boolean = false;
  orderFees: OrderFee[] = [];
  totalCost: number = 0;
  emptyGuid = '00000000-0000-0000-0000-000000000000';
  displayedColumns: string[] = ['description', 'amount', 'tax'];

  constructor(private orderFeesRepo: OrderFeeRepositoryService,
    private orderManager: OrderManagerService
  ) { }

  ngOnInit(): void {
    this.orderManager.currentOrder
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(order => {
        this.getFees(order);
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  getFees(order: Order) {
    if (order != null && order.id != undefined) {
      this.isComplete = order?.orderStatusTypeID == "Complete" || order?.orderStatusTypeID == "Cancelled";

      this.orderFeesRepo.getOrderFees(order!.id)
        .subscribe(fees => {
          this.orderFees = fees;

          if (!this.isComplete) {
            //Fetch expected Fees
            this.orderFeesRepo.getExpectedOrderFees(order!.id)
              .subscribe(expecteedfees => {
                this.orderFees = this.orderFees.concat(expecteedfees);
                this.calculateTotal();
              });
          }
          else
          {
            //If complete - no need to fetch expected fees
            this.calculateTotal();
          }
        });
    }
  }

  calculateTotal(): void {
    this.totalCost = this.orderFees.reduce((acc, fee) => acc + fee.amount + fee.gst + fee.pst + fee.hst, 0);
  }
}
