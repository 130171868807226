import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ListComponent, ReusablePartyRepositoryService, ReusableParty, ConfigurationState, UserGroupRepositoryService } from 'reg-hub-common';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-reusable-party-list',
  templateUrl: './reusable-party-list.component.html',
  styleUrls: ['./reusable-party-list.component.css']
})
export class ReusablePartyListComponent extends ListComponent<ReusableParty> implements OnInit {
  activeUsers: boolean = true;
  hasAccess: boolean = false;
  reusableParties: ReusableParty[] = []
  displayedColumns: string[] = ['partyTypeID',
    'fullName',
    'address'
  ];

  constructor(repo: ReusablePartyRepositoryService,
    private configurationState: ConfigurationState,
    private userGroupService: UserGroupRepositoryService,
    private router: Router) {
    super(repo);
  }

  ngOnInit(): void {
    this.configurationState.hasManageReusablePartyAccess().then(partyAccess => {

      this.hasAccess = partyAccess;

      this.configurationState.getUserGroupID().then(userGroupID => {
        this.userGroupService.getUserGroup(userGroupID).subscribe(userGroup => {
          this.repo.populateBaseLink([userGroup.corporationID]);
          this.getParties();
        })
      })
    });
  }

  getParties() {
    var defaultParams = new HttpParams().set('IsVisible', this.activeUsers);
    this.repo.getFirstPage(defaultParams);
  }

  navigateToAddReusableParty() {
    this.router.navigate([`/management/reusableParties/add`]);
  }
}