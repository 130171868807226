<div class="padding">
    <h1 class="left-box">Contact Us</h1>
    <div class="flex-container">
        <mat-card class="flex-item padding-top-bottom-5">
            <mat-card-content>
                <a class="flex-container">
                    <mat-icon class="icon-display padding-top-bottom-25 padding-sides-25">support_agent</mat-icon>
                    <div class="flex-item-2">
                        <h1>Operational/Client Support</h1>
                        <h3><a [href]="supportLink" class="primary">admin@reghub.ca</a></h3>
                        <h3><a [href]="supportphone" class="primary">1 (888) 508-0608</a> Monday-Friday 9:00 - 5:00 EST</h3>
                    </div>
                </a>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="flex-container">
        <mat-card class="flex-item padding-top-bottom-5">
            <mat-card-content>
                <a class="flex-container">
                    <mat-icon class="icon-display padding-top-bottom-25 padding-sides-25">support_agent</mat-icon>
                    <div class="flex-item-2">
                        <h1>Billing</h1>
                        <h3><a [href]="billingLink" class="primary">billing@reghub.ca</a></h3>
                    </div>
                </a>
            </mat-card-content>
        </mat-card>
    </div>
</div>